import React from 'react';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import { applyEditModeAttr } from '../Shared/Common/Helpers';
import { styled } from '@glitz/react';
import XhtmlComponent from '../Shared/XhtmlComponent/XhtmlComponent';
import { theme } from '../Theme';
import { media } from '@glitz/core';

import WebinarsDetailPageModel from './Models/WebinarsDetailPageModel.interface';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import BackToArticle from '../Shared/BackToArticle/BackToArticle';
import { translate } from '../Shared/Common/Translate';
import ImageRatio from '../Shared/Image/ImageRatio';
import ContentArea from '../Shared/ContentArea/ContentArea';
import Grid from '../Shared/Grid/Grid';

function WebinarDetailPage() {
  const {
    header,
    date,
    duration,
    location,
    contact,
    presenters,
    listingPageUrl,
    headerImage,
    contentArea,
    inEditMode,
  } = useCurrentPage<WebinarsDetailPageModel>();

  const {
    translations: {
      'webinarDetailPage/backToArticle': backToArticleText,
      'webinarDetailPage/backToArticleName': backToArticleName,
      'webinarDetailPage/date': dateLabel,
      'webinarDetailPage/location': locationLabel,
      'webinarDetailPage/duration': durationLabel,
      'webinarDetailPage/presenters': presenterLabel,
      'webinarDetailPage/contact': contactLabel,
    },
  } = useAppSettingsData();

  return (
    <>
      <Container>
        <BackToArticle
          title={translate(backToArticleText, [backToArticleName]).join(' ')}
          linkUrl={listingPageUrl}
        />
        <Content>
          {header && (
            <Title {...applyEditModeAttr(inEditMode && 'Title')}>
              {header}
            </Title>
          )}
          <AttributeList>
            {date && (
              <Attribute>
                {dateLabel} <Value>{date}</Value>
              </Attribute>
            )}
            {location && (
              <Attribute>
                {locationLabel} <Value>{location}</Value>
              </Attribute>
            )}
            {duration && (
              <Attribute>
                {durationLabel} <Value>{duration}</Value>
              </Attribute>
            )}
            {presenters && (
              <Attribute>
                {presenterLabel} <Value>{presenters}</Value>
              </Attribute>
            )}
            {contact && (
              <ContactWrapper>
                <Attribute>{contactLabel}</Attribute>
                <ContactInfo>
                  <XhtmlComponent content={contact} />
                </ContactInfo>
              </ContactWrapper>
            )}
          </AttributeList>
          {headerImage && (
            <ImageRatio
              src={headerImage.src || ''}
              alt={headerImage.alt || ''}
            />
          )}
        </Content>
        <Grid
          type={12}
          data-content-area
          {...applyEditModeAttr(inEditMode && 'MainContent')}
        >
          {Array.isArray(contentArea) && (
            <ContentArea childItems={contentArea} />
          )}
        </Grid>
      </Container>
    </>
  );
}

const Container = styled.div({
  width: '100%',
  maxWidth: theme.contentMaxWidth,
  margin: { x: 'auto', bottom: theme.spacing(16) },
  padding: {
    x: theme.spacing(4),
  },
  ...media(theme.mediaQuery.mediaMinLarge, {
    padding: {
      x: theme.none,
    },
  }),
});

const Content = styled.div({
  marginBottom: theme.spacing(8),
  ...media(theme.mediaQuery.mediaMinMedium, {
    marginBottom: theme.spacing(16),
    marginTop: theme.spacing(8),
  }),
  ...media(theme.mediaQuery.mediaMinLarge, {
    padding: {
      x: theme.spacing(2),
    },
  }),
});

const Title = styled.h1({
  font: {
    family: theme.fontFamily.heading,
    size: theme.iota,
  },
  color: theme.black,
  marginBottom: theme.spacing(4),
});

const AttributeList = styled.div({
  fontSize: theme.delta,
  marginBottom: theme.spacing(8),
});

const Attribute = styled.p({
  display: 'flex',
  font: {
    weight: theme.fontWeight.bold,
  },
  color: theme.black,
  lineHeight: theme.lineHeight.normal,
});

const Value = styled.span({
  font: {
    weight: theme.fontWeight.normal,
  },
  paddingLeft: theme.spacing(1),
});

const ContactWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const ContactInfo = styled.div({
  paddingLeft: theme.spacing(1),
});

export default WebinarDetailPage;
