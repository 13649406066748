import React from 'react';
import KexLink from '../../Shared/KexLink/KexLink';
import { styled, theme } from '../../../Features/Theme';
import { media } from '@glitz/core';
import ArrowRightIcon from './../Icons/ArrowRightIcon';

type PropTypes = {
  title: string;
  linkUrl: string;
};

function BackToArticle(props: PropTypes) {
  return (
    <Container>
      <KexLink href={props.linkUrl}>
        <Wrapper>
          <StyledArrowRightIcon />
          <styled.P>{props.title}</styled.P>
        </Wrapper>
      </KexLink>
    </Container>
  );
}

const Container = styled.div({
  ...media(theme.mediaQuery.mediaMinLarge, {
    padding: {
      x: theme.spacing(2),
    },
  }),
});

const Wrapper = styled.div({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  backgroundColor: theme.thinAqua,
  color: theme.blueDark,
  padding: {
    xy: theme.spacing(4),
  },
  font: {
    weight: theme.fontWeight.bold,
    size: theme.gamma,
  },
  ...media(theme.mediaQuery.mediaMinLarge, {
    padding: {
      x: theme.spacing(8),
      y: theme.spacing(4),
    },
  }),
});

const StyledArrowRightIcon = styled(ArrowRightIcon, {
  fill: theme.blueDark,
  width: theme.gamma,
  height: theme.beta,
  transform: 'rotate(-180deg)',
  marginRight: theme.spacing(2),
});

export default BackToArticle;
